<template>
    <r-e-dialog title="资产调拨" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" width="1000px" top="6vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" label-position="top" size="small" :model="formPublish" :rules="rules">
            <div class="title">基本信息</div>
            <div style="margin: 10px 0;border-bottom: 1px solid #F1F1F3;"></div>
            <div class="flex justify-between" style="width: 750px">
                <el-form-item label="调出房间" prop="oldCompanyUuid">
                    <el-select v-model="formPublish.oldCompanyUuid" filterable style="width: 300px;" placeholder="请选择">
                        <el-option v-for="(item,index) in roomList" :key="index" :label="item.name" :value="item.uuid"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="调入房间" prop="companyUuid">
                    <el-select v-model="formPublish.companyUuid" filterable style="width: 300px;" placeholder="请选择">
                        <el-option v-for="(item,index) in roomAllList" :key="index" :label="item.name" :value="item.uuid"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="说明" prop="remark">
                    <el-input type="textarea" v-model="formPublish.remark" :autosize="{ minRows: 2, maxRows: 4}" style="width: 750px;" placeholder="请输入内容"/>
                </el-form-item>
            </div>
            <div class="flex">
                <r-e-table class="bg-white" ref="rentTableRef" :data="dataList"
                           :columns="assetTransferToassetColumn" @select="selectTable" @select-all="selectAllTable"
                           :height="400" notAuto :showPagination="false">
                    <template slot="empty">
                        <el-empty/>
                    </template>
                </r-e-table>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import {assetTransferToassetColumn} from '@/views/property-management/assets-management/data';
    import {assetsAllocatePublish,allocateRoomlist,allocateRoomAllList,allocateNolist} from "@/api/assetTransfer";
    import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";
    export default {
        name: "dialog-add-assetTransfer",
        data() {
            return {
                dialogVisible: false,
                assetTransferToassetColumn,
                formPublish: {
                    companyUuid: '',
                    oldCompanyUuid: '',
                    remark:''
                },
                rules: {
                    companyUuid: [{required: true, message: '请选择调出仓库', trigger: 'change'}],
                    oldCompanyUuid: [{required: true, message: '请选择调入仓库', trigger: 'change'}],
                    remark: [{required: true, message: '请输入说明', trigger: 'blur'}],
                },
                assetTypeList: [],
                textarea: '',
                roomList: [],
                roomAllList: [],
                dataList: [],
                assetsUuids: []
            }
        },
        methods:{
            async openDialog() {
                let that = this;
                that.dialogVisible = true;
            },
            clickSubmit() {
                let that = this;
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        if (that.assetsUuids.length === 0) {
                            MessageWarning("请选择挑拨的资产");
                            return;
                        }
                        const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                        let {companyUuid, oldCompanyUuid, remark} = that.formPublish;
                        let assetsUuid = that.assetsUuids.join(",");
                        let data = {companyUuid, oldCompanyUuid, remark,assetsUuid};
                        assetsAllocatePublish(data).then(res => {
                            MessageSuccess('调拨成功');
                            that.clickCancel();
                            loading.close();
                        }).catch(err => {
                            MessageError('调拨失败');
                            loading.close();
                        });

                    }
                });
            },
            clickCancel() {
                this.$refs["formPublish"].resetFields();
                this.assetsUuids = [];
                this.dataList = [];
                this.$emit("handleSearch", false);
                this.dialogVisible = false;
            },
            allocateNolist(apartmentUuid){
                let that = this;
                allocateNolist({apartmentUuid}).then(res=>{
                    let {list} = res;
                    that.dataList = list;
                });
            },
            selectTable(selection, row){
                let that = this;
                if (selection.length !== 0) that.assetsUuids = selection.map(item=>item.uuid);
                else that.assetsUuids = [];
            },
            selectAllTable(selection){
                let that = this;
                if (selection.length !== 0) that.assetsUuids = selection.map(item=>item.uuid);
                else that.assetsUuids = [];
            },
        },
        async mounted() {
            let roomListInfo = await allocateRoomlist();
            this.roomList = roomListInfo.list;
            let roomAllListInfo = await allocateRoomAllList();
            this.roomAllList = roomAllListInfo.list;
        },
        watch:{
            'formPublish.oldCompanyUuid'(value){
                if(value) this.allocateNolist(value);
            }
        }
    }
</script>
<style lang="scss" scoped>
    /deep/ .el-form-item__label {
        line-height: 0 !important;
        padding: 0 0 5px 0 !important;
    }

    .title {
        position: relative;
        padding-left: 10px;
        font-size: 18px;

        &::before {
            width: 5px;
            height: 100%;
            content: '';
            background-color: #F5A623;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
</style>